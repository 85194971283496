<template>
  <!-- Main content -->
  <section class="section">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-info">
          <!-- Input Addons -->
          <div class="box-header with-border content">
            <h1>Update School Details</h1>
            <div class="box-tools is-pulled-right">
              <button v-if="$store.state.user.school.wonde_id" class="button" @click="wondeSync">
                <span class="icon"><img class="icon" src="/img/wondesmall.png"></span> <span>Sync with Wonde</span>
              </button>
              <button v-if="$store.state.user.school.clever_oid" class="button" @click="cleverSync">
                <span class="icon"><img class="icon" src="/img/clevericon.png"></span> <span>Sync with Clever</span> <span class="icon"><i class="fas fa-sync" /></span>
              </button>
              <button class="button is-info" @click="showManageCards = true">
                <span>Manage Cards</span>
              </button>
              <button v-if="$store.state.user.school.admin" type="button" class="button is-danger is-pulled-right" style="margin-left: 10px;" @click="deleteAllPupils">
                Delete All {{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }}
              </button>
              <button v-if="$store.state.user.school.admin" type="button" class="button is-warning is-pulled-right" style="margin-left: 10px;" @click="deleteAllGroups">
                Delete All Groups
              </button>
            </div>
          </div>
          <div class="box-body">
            <form class="ui form loginForm form-horizontal" @submit.prevent="validateBeforeSubmit">
              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('contact_name')}">
                <label for="contact_name" class="col-sm-2 control-label">*Billing contact name</label>
                <div class="input-group">
                  <span class="input-group-addon "><i class="fa fa-users" /></span>
                  <input
                    v-model="contact_name"
                    v-validate="'required'"
                    class="form-control"
                    name="contact_name"
                    placeholder="Name"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('contact_name')" class="help-block">{{ errors.first('contact_name') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('contact_email')}">
                <label for="contact_email" class="col-sm-2 control-label">*Contact email</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="contact_email"
                    v-validate="'required|email'"
                    class="form-control"
                    name="contact_email"
                    placeholder="Email"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('contact_email')" class="help-block">{{ errors.first('contact_email') }}</span>
              </div>

              <div class="form-group col-sm-12">
                <label for="contact_phone" class="col-sm-2 control-label">Contact phone</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="contact_phone"
                    class="form-control"
                    name="contact_phone"
                    placeholder="Phone (optional)"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('school_name')}">
                <label for="school_name" class="col-sm-2 control-label">*School name</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="school_name"
                    v-validate="'required'"
                    class="form-control"
                    name="school_name"
                    placeholder="School name"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('school_name')" class="help-block">{{ errors.first('school_name') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('school_address1')}">
                <label for="school_address1" class="col-sm-2 control-label">*Address 1</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="school_address1"
                    v-validate="'required'"
                    class="form-control"
                    name="school_address1"
                    placeholder="Address 1"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('school_address1')" class="help-block">{{ errors.first('school_address1') }}</span>
              </div>

              <div class="form-group col-sm-12">
                <label for="school_address2" class="col-sm-2 control-label">Address 2</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="school_address2"
                    class="form-control"
                    name="school_address2"
                    placeholder="Address 2 (optional)"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('school_town')}">
                <label for="school_town" class="col-sm-2 control-label">*Town / City</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="school_town"
                    v-validate="'required'"
                    class="form-control"
                    name="school_town"
                    placeholder="Town / city"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('school_town')" class="help-block">{{ errors.first('school_town') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('school_county')}">
                <label for="school_county" class="col-sm-2 control-label">*County / Region</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="school_county"
                    v-validate="'required'"
                    class="form-control"
                    name="school_county"
                    placeholder="Town / city"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('school_county')" class="help-block">{{ errors.first('school_county') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('school_postcode')}">
                <label for="school_postcode" class="col-sm-2 control-label">*Postcode / Zipcode</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="school_postcode"
                    v-validate="'required'"
                    class="form-control"
                    name="school_postcode"
                    placeholder="Town / city"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('school_postcode')" class="help-block">{{ errors.first('school_postcode') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('contact_name')}">
                <label for="contact_name" class="col-sm-2 control-label">*Billing contact name</label>
                <div class="input-group">
                  <span class="input-group-addon "><i class="fa fa-user" /></span>
                  <input
                    v-model="contact_name"
                    v-validate="'required'"
                    class="form-control"
                    name="contact_name"
                    placeholder="Name"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('contact_name')" class="help-block">{{ errors.first('contact_name') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('contact_email')}">
                <label for="contact_email" class="col-sm-2 control-label">*Billing Contact email</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-envelope" /></span>
                  <input
                    v-model="contact_email"
                    v-validate="'required|email'"
                    class="form-control"
                    name="contact_email"
                    placeholder="Email"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('contact_email')" class="help-block">{{ errors.first('contact_email') }}</span>
              </div>

              <div class="form-group col-sm-12">
                <label for="contact_phone" class="col-sm-2 control-label">Billing Contact phone</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-phone" /></span>
                  <input
                    v-model="contact_phone"
                    class="form-control"
                    name="contact_phone"
                    placeholder="Phone (optional)"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>

              <div class="form-group col-sm-12">
                <label for="" class="col-sm-2 control-label">&nbsp;</label>
                <div class="input-group">
                  <button class="button is-dark" @click="getBillingDetailsFromSchool">
                    <span>Copy School Name / Address</span> <span class="icon"><i class="fas fa-angle-double-down" /></span>
                  </button>
                </div>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('billing_name')}">
                <label for="billing_name" class="col-sm-2 control-label">*Billing name</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-building" /></span>
                  <input
                    v-model="billing_name"
                    v-validate="'required'"
                    class="form-control"
                    name="billing_name"
                    placeholder="Billing name (school / academy trust / company name)"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('billing_name')" class="help-block">{{ errors.first('billing_name') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('billing_address1')}">
                <label for="billing_address1" class="col-sm-2 control-label">*Billing Address 1</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-building" /></span>
                  <input
                    v-model="billing_address1"
                    v-validate="'required'"
                    class="form-control"
                    name="billing_address1"
                    placeholder="Address 1"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('billing_address1')" class="help-block">{{ errors.first('billing_address1') }}</span>
              </div>

              <div class="form-group col-sm-12">
                <label for="billing_address2" class="col-sm-2 control-label">Billing Address 2</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-building" /></span>
                  <input
                    v-model="billing_address2"
                    class="form-control"
                    name="billing_address2"
                    placeholder="Address 2 (optional)"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('billing_town')}">
                <label for="billing_town" class="col-sm-2 control-label">*Billing Town / City</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-building" /></span>
                  <input
                    v-model="billing_town"
                    v-validate="'required'"
                    class="form-control"
                    name="billing_town"
                    placeholder="Town / city"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('billing_town')" class="help-block">{{ errors.first('billing_town') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('billing_county')}">
                <label for="billing_county" class="col-sm-2 control-label">*Billing County / State</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-building" /></span>
                  <input
                    v-model="billing_county"
                    v-validate="'required'"
                    class="form-control"
                    name="billing_county"
                    placeholder="County / State"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('billing_county')" class="help-block">{{ errors.first('billing_county') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('billing_postcode')}">
                <label for="billing_postcode" class="col-sm-2 control-label">*Billing Postcode / Zipcode</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-building" /></span>
                  <input
                    v-model="billing_postcode"
                    v-validate="'required'"
                    class="form-control"
                    name="billing_postcode"
                    placeholder="Postcode"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('billing_postcode')" class="help-block">{{ errors.first('billing_postcode') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('school_country')}">
                <label for="school_country" class="col-sm-2 control-label">*Country</label>
                <div class="input-group col-sm-10">
                  <select
                    v-if="countriesData"
                    v-once
                    id="school_country"
                    v-model="school_country_code"
                    class="form-control input-group"
                    name="school_country"
                  >
                    <option v-for="country in countriesData" :value="country.code">
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <span v-show="errors.has('school_country')" class="help-block">{{ errors.first('school_country') }}</span>
              </div>

              <div v-if="countriesData && reverseCharge" :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('vat_number')}">
                <label for="vat_number" class="col-sm-2 control-label">VAT No.</label>
                <div class="input-group">
                  <span class="input-group-addon">{{ selectedCountryCode }}</i></span>
                  <input
                    v-model="vat_number"
                    v-validate="'euvat'"
                    class="form-control"
                    name="vat_number"
                    placeholder="VAT Number (for EU zero rated VAT)"
                    type="text"
                    autocomplete="off"
                  >
                </div>

                <span v-show="errors.has('vat_number')" class="help-block">{{ errors.first('vat_number') }}</span>
              </div>

              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('school_type')}">
                <label for="school_type" class="col-sm-2 control-label">*School Type</label>
                <div class="input-group col-sm-10">
                  <select
                    v-model="school_type"
                    v-validate="'required'"
                    class="form-control input-group"
                    name="school_type"
                    placeholder="Town / city"
                    type="text"
                  >
                    <option>Primary</option>
                    <option>Secondary</option>
                    <option>All-Through</option>
                    <option>School Group</option>
                    <option>Special Provision / PRU</option>
                    <option>Private Tutor</option>
                    <option>Other</option>
                  </select>
                </div>
                <span v-show="errors.has('school_type')" class="help-block">{{ errors.first('school_type') }}</span>
              </div>

              <div class="form-group col-sm-12">
                <label for="password" class="col-sm-2 control-label">Region*</label>
                <div class="input-group col-sm-10">
                  <select v-model="locale" class="form-control input-group">
                    <option value="en_AU">
                      🇦🇺 Australia
                    </option>
                    <option value="en_IE">
                      🇮🇪 Ireland
                    </option>
                    <option value="en_NZ">
                      🇳🇿 New Zealand
                    </option>
                    <option value="en_GB">
                      🇬🇧 United Kingdom
                    </option>
                    <option value="en_US">
                      🇺🇸 United States
                    </option>
                  </select>
                  <p><small><i>The region chosen will affect the default spelling list language and voice used in our game.</i></small></p>
                </div>
              </div>

              <div class="form-group col-sm-12">
                <label for="password" class="col-sm-2 control-label">Pupil League length*</label>
                <div class="input-group col-sm-10">
                  <b-radio
                    v-model="leagues"
                    native-value="0"
                  >
                    Off
                  </b-radio>
                  <b-radio
                    v-model="leagues"
                    native-value="10"
                  >
                    Top 10
                  </b-radio>
                  <b-radio
                    v-model="leagues"
                    native-value="999999"
                  >
                    All
                  </b-radio>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <button type="submit" :class="'btn button is-link btn-lg ' + loading">
                    Update
                  </button>
                </div>
              </div>
            </form>
            <p>&nbsp;</p>
            <!-- errors -->
            <div v-if="response" class="alert alert-error">
              <p>{{ response }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showManageCards" class="modal is-active">
      <div class="modal-background" />
      <div class="modal-content box">
        <!-- Any other Bulma elements you want -->
        <h3>Manage Cards</h3>

        <StripeCardPayment :disabled="false" :zip="$store.state.user.school.billing_postcode" :meta="{type:'manage.cards'}" />
      </div>
      <button class="modal-close is-large" aria-label="hideUpgradeStripe" @click="showManageCards = false" />
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { Validator } from 'vee-validate'
import _ from 'lodash'
import StripeCardPayment from '@/components/views/components/StripeCardPayment'
import { request } from '@/edshed-common/api'
import utils from '../../utils'

export default {
  name: 'EditSchool',
  components: {
    StripeCardPayment
  },
  data (router) {
    return {
      loading: '',
      loadingScreen: null,
      leagues: '' + this.$store.state.user.school.league_length || '999999',
      countriesData: null,
      contact_name: this.$store.state.user.school.contact_name,
      contact_email: this.$store.state.user.school.contact_email,
      contact_phone: this.$store.state.user.school.contact_phone,
      school_name: this.$store.state.user.school.school_name,
      school_address1: this.$store.state.user.school.school_address1,
      school_address2: this.$store.state.user.school.school_address2,
      school_town: this.$store.state.user.school.school_town,
      school_county: this.$store.state.user.school.school_county,
      school_postcode: this.$store.state.user.school.school_postcode,
      billing_name: this.$store.state.user.school.billing_name,
      billing_address1: this.$store.state.user.school.billing_address1,
      billing_address2: this.$store.state.user.school.billing_address2,
      billing_town: this.$store.state.user.school.billing_town,
      billing_county: this.$store.state.user.school.billing_county,
      billing_postcode: this.$store.state.user.school.billing_postcode,
      school_country_code: this.$store.state.user.school.school_country_code,
      school_type: this.$store.state.user.school.school_type,
      vat_number: this.$store.state.user.school.vat_number,
      locale: this.$store.state.user.school.locale,
      response: '',
      showManageCards: false
    }
  },
  computed: {
    school_country () {
      let cnt = this.$store.state.user.school.school_country
      if (this.countriesData) {
        const c = _.filter(this.countriesData, { code: this.school_country_code })
        console.log(c)
        cnt = c[0].name
      }
      return cnt
    },
    selectedCountryCode () {
      let cd = ''
      if (this.countriesData) {
        const c = _.filter(this.countriesData, { code: this.school_country_code })
        // console.log(c)
        const cda = c[0].code
        const cds = cda.split('-')
        cd = cds[0]
      }
      return cd
    },
    reverseCharge () {
      let rc = false
      if (this.countriesData) {
        const c = _.filter(this.countriesData, { code: this.school_country_code })
        // console.log(c)
        rc = c[0].reverse_charge === 1
      }
      return rc
    },
    vatNum () {
      return this.selectedCountryCode + this.vat_number
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadCountries()
      this.checkAddToStripe()
      const dictionary = {
        en_GB: {
          attributes: {
            contact_name: 'Billing Contact Name',
            contact_email: 'Contact Email',
            contact_phone: 'Contact Phone Number',
            school_name: 'School Name',
            school_address1: 'School Address',
            school_address2: '',
            school_town: 'School Town / City',
            school_county: 'County / State',
            school_postcode: 'Postcode / Zipcode',
            billing_name: 'Billing Name',
            billing_address1: 'Billing Address',
            billing_address2: '',
            billing_town: 'Billing Town / City',
            billing_county: 'Billing County / State',
            billing_postcode: 'Billing Postcode / Zipcode',
            vat_number: 'VAT Number',
            school_country: 'Country',
            school_type: 'School Type',
            terms: 'Terms'
          }
        }
      }
      Validator.localize('en_GB', dictionary.en_GB)
      Validator.extend('euvat', {
        getMessage: field => 'The ' + field + ' value is not valid.',
        validate: value => utils.isEuroVat(value)
      })
    })
  },
  methods: {
    deleteAllPupils () {
      const c = confirm('Are you sure you want to delete all pupils for your school? This cannot be undone.')
      if (c) {
        request('DELETE', 'users/me/school/pupils', { pupils: '*' })
          .then((response) => {
          // var pupils = response.data.pupils
          // this.pupilData = pupils
          // this.checkedRows = []
          // this.searchDidChange()
            alert('Success')
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    deleteAllGroups () {
      const c = confirm('Are you sure you want to delete all groups for your school? This cannot be undone.')
      if (c) {
        request('DELETE', 'users/me/school/groups', null)
          .then((response) => {
            alert('Success')
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    getBillingDetailsFromSchool () {
      this.billing_name = this.school_name
      this.billing_address1 = this.school_address1
      this.billing_address2 = this.school_address2
      this.billing_town = this.school_town
      this.billing_county = this.school_county
      this.billing_postcode = this.school_postcode
    },
    wondeSync () {
      if (!this.$store.state.user.school.wonde_id) {
        return
      }
      this.showLoadingScreen()
      request('POST', 'schools/' + this.$store.state.user.school.wonde_id + '/wondesync', null)
        .then((response) => {
          const data = response.data
          console.log(data)
          this.closeLoadingScreen()
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
          alert('Data sync requested. There may be a delay before processing completes.')
        })
        .catch((error) => {
          this.closeLoadingScreen()
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    cleverSync () {
      if (!this.$store.state.user.school.clever_oid) {
        return
      }
      this.showLoadingScreen()
      request('POST', 'schools/' + this.$store.state.user.school.clever_oid + '/cleversync', null)
        .then((response) => {
          const data = response.data
          console.log(data)
          this.closeLoadingScreen()
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
          alert('Data sync requested. There may be a delay before processing completes.')
        })
        .catch((error) => {
          this.closeLoadingScreen()
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    showLoadingScreen () {
      // this.loadingScreen = this.$buefy.loading.open()
    },
    closeLoadingScreen () {
      // this.loadingScreen.close()
    },
    checkAddToStripe () {
      // if no stripe_customer property for school add to stripe and refresh local user object to incluce new stripe ID
      if (this.$store.state.user.school.stripe_customer === null) {
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
        request('POST', 'users/me/school/addstripe', null)
          .then((response) => {
            const data = response.data
            console.log(data)
            // update local user object
            this.$store.commit('SET_USER', data.user)

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }

            this.toggleLoading()
            this.$store.commit('TOGGLE_LOADING')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    loadCountries () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      // var that = this
      request('GET', 'countries', null)
        .then((response) => {
          const data = response.data
          this.countriesData = data.countries
          // console.log('readerData: ' + this.readerData)
          if (!this.countriesData || this.countriesData.length === 0) {
            this.response = 'No Countries'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.checkCreds()
        }

        // alert('Correct them errors!')
      })
    },
    checkCreds (e) {
      const { contact_name, contact_email, contact_phone, school_name, school_address1, school_address2, school_town, school_county, school_postcode, billing_name, billing_address1, billing_address2, billing_town, billing_county, billing_postcode, school_country, school_country_code, school_type, terms, locale, vatNum, leagues } = this

      console.log(utils.isEmail)

      this.toggleLoading()
      this.resetResponse()
      this.$store.commit('TOGGLE_LOADING')

      /* Making API call to authenticate a user */
      request('PUT', 'users/me/school', { contact_name, contact_email, contact_phone, school_name, school_address1, school_address2, school_town, school_county, school_postcode, billing_name, billing_address1, billing_address2, billing_town, billing_county, billing_postcode, school_country, school_country_code, school_type, terms, locale, vatNum, leagues })
        .then((response) => {
          this.toggleLoading()
          window.console.log('Response', response)
          const data = response.data
          /* Checking if error object was returned from the server */

          /* Setting user in the state and caching record to the localStorage */
          if (data.user) {
            this.$store.commit('SET_USER', data.user)

            // console.log('user: ' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }
            console.log('redirecting')
            this.response = 'Details updated'
          }
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    resetResponse () {
      this.response = ''
    }
  }
}
</script>
<style>

</style>
